

let model = {};

// dữ liệu form
model.dataForm = {
  "id": undefined,
  "index": undefined, // optional - Số thứ tự
  "code": undefined, // optional - Mã
  "label": undefined, // optional - Nhãn hiển thị
  "icon_id": undefined, // optional, ref: mushroom_file - File icon
  "value_settings": [ // optional - Cài đặt hiển thị giá trị
  {
      "min": undefined, // optional - Giá trị nhỏ nhất
      "max": undefined, // optional - Giá trị lớn nhất
      "color": undefined, // optional - Màu
  }], 
  "unit": undefined, // optional - Đơn vị
  "visible": true, // optional - Có hiển thị không
  "link": undefined, // optional - Link chuyển hướng
  "tooltip": undefined, // optional - Tooltip
};

model.formatColumnsTable = {
  code: undefined, // optional - Mã trường
  field_name: undefined, // required - Tên trường
  title_name: undefined, // optional - Tên cột
  // optional - Cấu hình icon
  icon: {
    visible: undefined, // optional - Cho phép hiển thị không
    file_id: undefined, // optional, ref: icon - Id file icon
  },
};

model.targetDomains = [
  { value: "primary", label: "Primary" },
  { value: "probe", label: "Probe" },
  { value: "bras", label: "Bras" },
];

model.targetType = [
  { value: "static", label: "Static" },
  { value: "service", label: "Service" },
  { value: "table_screen", label: "Table screen" },
  { value: "usecase", label: "Usecase" },
  { value: "dynamic", label: "Dynamic" },
];

model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "index",
  defaultSort: ["index", "ascending"],
  fields: "id,index,code,label,icon_id,value_settings,unit,visible,link,tooltip,created_time,creator_id,last_modified_time,last_modifier_id",
  filters: "",
  dataSearch: {
      value: {
          code: "",
          label: "",
          visible: undefined,
      },
      // valid: {
      //     // name: [ValidService.checkNameAllowVN],
      //     code: [ValidService.checkNumber],
      //     type: [ValidService.checkNameAllowVN],
      // },
      operator: {
        code: ":regex_i:",
        label: ":regex_i:",
      },
  },
};
export default model;
